import React, { Suspense } from 'react';
import { Outlet } from "react-router-dom";
import { Footer, Header } from '../components';
import { Grid } from '@mui/material';


const LayoutHome = () => {

    return (
        <>
            <Header />
            <main>
                <Suspense fallback={<div>Cargando...</div>}>
                    <Grid container sx={{marginBottom:4}}>
                        <Outlet />
                    </Grid>
                </Suspense>
            </main>
            <Footer />
        </>
    );
}

export default LayoutHome;