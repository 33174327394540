import React from 'react';
import { List, ListItem, Grid } from '@mui/material'
import { VerifiedUserSharp } from '@mui/icons-material'


const Tasks = (props) => {

    return (
        <List>
            {
                props.items ?
                    props.items.map((item) => (
                        <ListItem key={item.id}>
                            <Grid container sx={{ fontSize: "0.8rem", textAlign: "justify" }} key={item.id}>
                                <Grid item xs={1} sm={0.5}>
                                    <VerifiedUserSharp sx={{ fontSize: "small", color: "#3b7b9f", marginRight: 2 }} />
                                </Grid>
                                <Grid item xs={11} sm={11.5}>
                                    {item.name}
                                </Grid>
                            </Grid>
                        </ListItem >
                    ))
                    : undefined
            }
        </List>
    );
}

export default Tasks;

