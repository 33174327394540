import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material'
import Categories from './Categories';


const Service = (props) => {

    return (
        <Card sx={{ background: "#00000005" }}>
            <CardHeader sx={{ color: "#3b7b9f" }} title={props.item.name} subheader={props.item.description} />
            <CardContent>
                {
                    props.item.categories ? <Categories items={props.item.categories} /> : undefined
                }
            </CardContent>
        </Card>
    );
}

export default Service;