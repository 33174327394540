import React from 'react';
import { useParams } from "react-router-dom"
import { Typography, Grid, Divider } from '@mui/material'
import { ServicesList, Service } from '../components'
import { Services as ServicesData } from '../data'


const Services = () => {
    const params = useParams()
    
    const service = ServicesData.find(s => {
        return s.id === Number(params.id);
    });

    return (
        <Grid sx={{ textAlign: "center" }} container direction="column" alignItems="center">
            <Grid sx={{ width: "100%" }} item>
                <Divider variant="middle" ><Typography variant="h4">SERVICIOS</Typography></Divider>
            </Grid>
            <Grid sx={{ paddingTop: 5 }} item >
                <ServicesList items={ServicesData} source="services" />
            </Grid>
            {service ?
                <Grid sx={{ paddingTop: { xs: 4, md: 10 }, width: "100%" }} item>                   
                   <Service item={service}/>
                </Grid>
                : undefined
            }
        </Grid>
    );
}

export default Services;