import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Alert, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material'
import emailjs from '@emailjs/browser';
import * as Constants from '../utils/Constants';



const SendEmail = () => {

    const form = useRef();

    const [errorSendingEmail, setErrorSendingEmail] = useState(false);
    const [viewAlert, setViewAlert] = useState(false)
    const [validationWithErrors, setValidationWithErrors] = useState(true);
    const [sending, setSending] = useState(false)
    const [formData, setFormData] = useState({
        userName: "",
        userEmail: "",
        subject: "",
        message: ""
    });
    const [errors, setErrors] = useState({
        userName: {
            error: false,
            message: ""
        },
        userEmail: {
            error: false,
            message: ""
        },
        subject: {
            error: false,
            message: ""
        }
    });

    const _changeControls = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const validateformData = (control) => {
        if ((Constants.RegEx_MinimalCharacters.test(formData.userName)) &&
            (Constants.RegEx_Email.test(formData.userEmail)) &&
            (Constants.RegEx_MinimalCharacters.test(formData.subject))
        ) {
            setValidationWithErrors(false);
        } else {
            setValidationWithErrors(true);
        }

        switch (control) {
            case "userName":
                setErrors({
                    ...errors, [control]:
                    {
                        error: (Constants.RegEx_MinimalCharacters.test(formData.userName)) ? false : true,
                        message: (Constants.RegEx_MinimalCharacters.test(formData.userName)) ? "" : "Se requiere más de 3 letras"
                    }
                })
                break;

            case "userEmail":
                setErrors({
                    ...errors, [control]:
                    {
                        error: (Constants.RegEx_Email.test(formData.userEmail)) ? false : true,
                        message: (Constants.RegEx_Email.test(formData.userEmail)) ? "" : "Ingrese un email válido"
                    }
                })
                break;

            case "subject":
                setErrors({
                    ...errors, [control]: {
                        error: (Constants.RegEx_MinimalCharacters.test(formData.subject)) ? false : true,
                        message: (Constants.RegEx_MinimalCharacters.test(formData.subject)) ? "" : "Se requiere más de 3 letras"
                    }
                })
                break;

            default: break;
        }
    }

    const resetForm = () => {
        setErrorSendingEmail(false)
        setValidationWithErrors(true);

        setFormData(
            {
                userName: "",
                userEmail: "",
                subject: "",
                message: ""
            }
        );
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setSending(true);

        emailjs.sendForm(Constants.EmailJS_ServiceId, Constants.EmailJS_TemplateId, form.current, Constants.EmailJS_PublicKey)
            .then((result) => {                
                resetForm()
            }).catch((error) => {
                setErrorSendingEmail(true)
            }).finally(() => {
                setViewAlert(true)
                setSending(false)
            })
    };

    const alertSendEmail =
        <Grid item display={viewAlert ? "block" : "none"}>
            {
                !errorSendingEmail ?
                    <Alert severity="success">Email enviado correctamente. Gracias por contactar con nosotros.</Alert>
                    :
                    <Alert severity="error">Error al enviar el correo. Por favor, inténtelo más tarde.</Alert>
            }
        </Grid>

    useEffect(() => {
        if (viewAlert) {
            setTimeout(() => {
                setViewAlert(false)
            }, 5000)
        }
    }, [viewAlert]);

    return (
        <Box component="form" ref={form} onSubmit={sendEmail}>
            <Grid width="100%" container direction="column" spacing={2} >
                <Grid item>
                    <TextField required fullWidth id="userName" type="text" label="Nombre y apellidos" name="userName"
                        error={errors.userName.error} helperText={errors.userName.message} value={formData.userName} onChange={(e) => _changeControls(e)} onBlur={(e) => validateformData(e.target.name)} />
                </Grid>
                <Grid item>
                    <TextField required fullWidth id="userEmail" type="email" label="Email" name="userEmail"
                        error={errors.userEmail.error} helperText={errors.userEmail.message} value={formData.userEmail} onChange={(e) => _changeControls(e)} onBlur={(e) => validateformData(e.target.name)} />
                </Grid>
                <Grid item>
                    <TextField required fullWidth id="subject" type="text" label="Asunto" name="subject"
                        error={errors.subject.error} helperText={errors.subject.message} value={formData.subject} onChange={(e) => _changeControls(e)} onBlur={(e) => validateformData(e.target.name)} />
                </Grid>
                <Grid item>
                    <TextField fullWidth multiline id="message" type="text" label="Mensaje" rows={4} name="message"
                        value={formData.message} onChange={(e) => _changeControls(e)} />
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                    <LoadingButton variant="contained" type="submit" startIcon={<Send />} loading={sending} disabled={validationWithErrors}>Enviar</LoadingButton>
                </Grid>
                {alertSendEmail}
            </Grid>
        </Box>
    );
}

export default SendEmail;