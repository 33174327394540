import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LayoutPublic, LayoutPrivate, LayoutHome } from './layouts';
import { Home, About, Services, Experiences, Contact, Projects, TechnicalProposals, Error } from './pages';
import { ProtectedRouter } from './utils';


const App = () => {
  return (
    <Routes>
      <Route element={<LayoutHome />} errorElement={<Error />} >
        <Route path="/" element={<Home />} />
      </Route>
      <Route element={<LayoutPublic />} errorElement={<Error />} >
        <Route path="services" element={<Services />}>
          <Route path=":id" element={<Services />} />
        </Route>
        <Route path="projects" element={<Projects />}>
          <Route path=":id" element={<Projects />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="experiences" element={<Experiences />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Error message='Pagina no encontrada!' />} />
        <Route path="error" element={<Error />} />
        <Route path="not-authenticated" element={<Error message='Esta es una zona privada, necesita autenticarse!' />} />
      </Route>
      <Route element={<LayoutPrivate />} errorElement={<Error />} >
        <Route path="technical-proposals" element={<ProtectedRouter ><TechnicalProposals /></ProtectedRouter>} />
      </Route>
    </Routes>
  );
}

export default App;