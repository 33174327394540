import React from 'react';
import { Typography, Grid, Box, IconButton } from '@mui/material'
import { Email, WhatsApp } from '@mui/icons-material';
import { Map, SendEmail } from '../components'


const About = () => {

    return (
        <Grid container spacing={{ xs: 6, md: 22 }}>
            <Grid sx={{ fontSize: "0.9rem" }} item xs={12} md={6}>
                <Typography sx={{ paddingBottom: 2 }} variant="h5" >ENCUÉNTRANOS</Typography>
                <Grid item container direction="column">
                    <Box sx={{ paddingBotton: 2 }}>
                        <Map />
                    </Box>
                    <p>Urbanización Ceres Mz. D - Lt. 37, Oficina 201 <br />
                        Ate Vitarte - Lima <br />
                        PERÚ
                    </p>
                    <Box>
                        <Box sx={{ fontWeight: "bold", display: "inline-block" }}>Teléfono:</Box><IconButton sx={{ color: "#25D366" }} href="https://wa.me/51993711539" target="_blank"><WhatsApp /></IconButton>+511 993711539<br />
                        <Box sx={{ fontWeight: "bold", display: "inline-block" }}>Email:</Box><IconButton sx={{ color: "#3b7b9f" }} href="mailto:informes@hydroelectricsrl.com" target="_blank"><Email /></IconButton>informes@hydroelectricsrl.com
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} >
                <Typography sx={{ paddingBottom: 2 }} variant="h5">CONSÚLTANOS</Typography>
                <SendEmail />
            </Grid>
        </Grid>
    );
}

export default About;