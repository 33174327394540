import React from 'react';
import { Grid, Divider, Card, CardMedia, CardContent, CardActionArea } from '@mui/material'
import { useNavigate } from 'react-router-dom';


const ServicesList = (props) => {

    const navigate = useNavigate()

    const _navegate = (event, serviceId) => {
        navigate("/" + props.source + "/" + serviceId)
    }

    return (
        <>
            <Grid sx={{ width:"100%"}} container direction="row" spacing={{ xs: 2, sm: 4 }} >
                {props.items.map((service) => (
                    <Grid item xs={6} sm={3} md={3} lg={3} key={service.id}>
                        <Card sx={{ maxWidth: { xs: 180, sm: 300, md: 500, lg: 500 } }} key={service.id}>
                            <CardActionArea onClick={(e) => { _navegate(e, service.id) }}>
                                <CardMedia component="img" sx={{ maxHeight: 140 }}
                                    image={require("../images/services/" + service.image)}
                                    alt={service.name}
                                />
                                <Divider variant="inset" ></Divider>
                                <CardContent sx={{ background: "#3b7b9f", color: "white", fontSize: { xs: "0.65rem", md: "0.85rem" } }}>
                                    {service.name}
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default ServicesList;