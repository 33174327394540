import React from 'react';
import { Typography, CardMedia, Grid, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material'
import { About_Company } from '../images';
import * as Data from '../data'


const objetivesList =
    <ul>
        {Data.Objetives.map((o) => {
            return (
                <li key={o.id}>{o.title}</li>
            )
        })}
    </ul>;

const offersList =
    <ul>
        {Data.Offers.map((o) => {
            return (
                <li key={o.id}>{o.title}</li>
            )
        })}
    </ul>;

const historyList =
    <TableContainer >
        <Table>
            <TableBody>
                {Data.History.map((o) => {
                    return (
                        <TableRow sx={{ border: 0, }} key={o.id}>
                            <TableCell sx={{ fontSize: "0.8rem", textAlign: "justify" }}>{o.year}</TableCell>
                            <TableCell sx={{ fontSize: "0.8rem", textAlign: "justify" }}>{o.title}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </TableContainer>;

const About = () => {

    return (
        <Grid container direction="row" spacing={{ xs: 6, md: 16 }}>
            <Grid sx={{ fontSize: "0.8rem", textAlign: "justify" }} item xs={12} md={7.5}>
                <Typography sx={{ paddingBottom: 2 }} variant="h5">LA EMPRESA</Typography>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CardMedia component="img" image={About_Company} alt="Sobre nosotros" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <p>Somos una sociedad que tiene por objetivo dedicarse a:</p>
                        {objetivesList}
                        <p>Ofrecemos a nuestros clientes:</p>
                        {offersList}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4.5}>
                <Typography sx={{ paddingBottom: 2 }} variant="h5">BREVE HISTORIA</Typography>
                {historyList}
            </Grid>
        </Grid>
    );
}

export default About;