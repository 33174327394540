import React from 'react';
import { List, ListItem, Grid } from '@mui/material';
import { VerifiedSharp } from '@mui/icons-material'
import Tasks from './Tasks';


const Categories = (props) => {

    return (
        <List>
            {
                props.items ?
                    props.items.map((item) => (
                        <ListItem key={item.id}>
                            <Grid container sx={{ fontSize: "0.9rem", textAlign: "justify" }}>
                                <Grid item xs={1} sm={0.5}>
                                    <VerifiedSharp sx={{ fontSize: "medium", color: "#3b7b9f", marginRight: 2 }} />
                                </Grid>
                                <Grid item xs={11} sm={11.5}>
                                    {item.name}
                                </Grid>
                                <Grid item sx={{ marginLeft: 3 }}>
                                    <Tasks items={item.tasks} />
                                </Grid>
                            </Grid>
                        </ListItem >
                    ))
                    : undefined
            }
        </List>
    );
}

export default Categories;

