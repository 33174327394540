import ImageGallery from "react-image-gallery";
import { Grid, Typography } from "@mui/material";
import "./gallery.css";


const Gallery = (props) => {
    return (
        <Grid container direction="column" alignItems="center">
        <Grid item>
            {
                props.title ? <Typography sx={{ textAlign: "center", paddingTop: 1, paddingBottom: 1 }} variant="h4">{props.title}</Typography> : undefined
            }
            
            <ImageGallery
                items={props.items}
                showThumbnails={props.showThumbnails}
                thumbnailPosition = {props.thumbnailPosition}
                autoPlay={props.autoPlay}
                showNav={props.showNav}
                slideDuration={props.slideDuration}             
            />
            </Grid>
        </Grid>
    );
}

export default Gallery; 