import React from 'react';
import {Container, Typography, Card, Grid} from '@mui/material'


const TechnicalProposals = () => { 

    return (  
      <>      
        <Container>
          <Card className=""> 
            <Grid container className="">
              <Grid>
                <Typography className="">Technical Proposals</Typography>
              </Grid>
              <Grid>                
              </Grid>
            </Grid>         
          </Card>
        </Container>
       </>   
    );  
}

export default TechnicalProposals;