import React from 'react';
import { List, ListItem,Box, ListItemText, Typography, Divider } from '@mui/material'


const Tasks = (props) => {

    return (
        <List>
            {
                props.items ?
                    props.items.map((item) => (
                        <Box key={props.customer + item.id}>
                            <ListItem key={props.customer + item.id}>
                                <ListItemText 
                                    primary={item.plant}
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: "inline", paddingRight:2}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {item.dates} 
                                            </Typography>                                            
                                            {item.description}
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider variant="middle" component="li" />
                        </Box>
                    ))
                    : undefined
            }
        </List>
    );
}

export default Tasks;