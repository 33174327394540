import React from 'react';
import { useParams } from "react-router-dom"
import { Typography, Grid, Divider } from '@mui/material'
import { ServicesList, ServiceCustomer } from '../components'
import { Projects as ProjectsData, Services } from '../data'


const Projects = () => {
    const params = useParams()

    const customers = ProjectsData.filter(p => {
        return p.serviceId === Number(params.id);
    });

    const service = Services.find(s => {
        return s.id === Number(params.id);
    });

    return (
        <Grid sx={{ textAlign: "center" }} container direction="column" alignItems="center">
            <Grid sx={{ width: "100%" }} item>
                <Divider variant="middle" ><Typography variant="h4">PROYECTOS</Typography></Divider>
            </Grid>
            <Grid sx={{ paddingTop: 5 }} item >
                <ServicesList items={Services} source="projects" />
            </Grid>
            {service ?
                <Grid sx={{ paddingTop: { xs: 4, md: 10 }, width: "100%" }} item>
                    <ServiceCustomer items={customers} service={service} />
                </Grid>
                : undefined
            }
        </Grid>
    );
}

export default Projects;