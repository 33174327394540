import { createTheme } from '@mui/material';


const CustomTheme = () => createTheme({
  palette: {
    type: "light",
    primary: {
      main: '#3b7b9f',
    },
    secondary: {
      main: '#3e3e3f',
    },
  },
})

export default CustomTheme;