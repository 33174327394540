//Google Maps
export const GoogleMapsId = "hydroelectric+ingenieros";
export const GoogleMaps_ApiKey = "AIzaSyCvddDUjwCn-2xpvEm4D0gzbJBLPQQxRas";
export const Latitude = -12.026841052884754;
export const Longitude = -76.92529027750194;


//EmailJS
export const EmailJS_ServiceId = "service_v0dtxjx";
export const EmailJS_PublicKey = "eYMvpidfvDndheMsz";
export const EmailJS_TemplateId = "template_gh2onia";


//RegEx
export const RegEx_Email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const RegEx_MinimalCharacters = /(.*[a-z]){3}/i;
