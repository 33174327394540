import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';

const ProtectedRouter = ({ children }) => {  
  const user  = useAuth()
  
  if (!user.isAuthenticated) {
    return <Navigate to='/not-authenticated'/>
  }
    
  return children  
}

export default ProtectedRouter