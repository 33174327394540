import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material'
import Customers from './Customers';

const Service = (props) => {

    return (
        <Card>
            <CardHeader sx={{ color: "#3b7b9f" }} title={props.service.name} subheader={props.service.description} />            
            <CardContent>               
                <Customers items={props.items} />            
            </CardContent>
        </Card>
    );
}

export default Service;