import React from 'react';
import { Gallery } from '../components'
import { Typography, Divider, Grid } from '@mui/material'


const images = [
    {
        original: require("../images/experiences/exp1.jpg"),
        thumbnail: require("../images/experiences/exp1.jpg"),
        description: "C. H. Runatullo III (20 MW)"
    },    
    {
        original: require("../images/experiences/exp2.jpg"),
        thumbnail: require("../images/experiences/exp2.jpg"),
        description: "Presa Tablachaca de la C. H. Mantaro (812 MW)"
    },
    {
        original: require("../images/experiences/exp3.jpg"),
        thumbnail: require("../images/experiences/exp3.jpg"),
        description: "C. H. Runatulo II (20 MW)"
    },
    {
        original: require("../images/experiences/exp4.jpg"),
        thumbnail: require("../images/experiences/exp4.jpg"),
        description: "C. H. Runatulo II (20 MW)"
    },
    {
        original: require("../images/experiences/exp5.jpg"),
        thumbnail: require("../images/experiences/exp5.jpg"),
        description: "C. H. Runatullo III (20 MW)"
    }
];

const Experiences = () => {

    return (
        <Grid sx={{ textAlign: "center" }} container direction="column" alignItems="center">
            <Grid sx={{ width: "100%" }} item>
                <Divider variant="middle" ><Typography variant="h4">EXPERIENCIAS</Typography></Divider>
            </Grid>
            <Grid sx={{ paddingTop: 5 }} item>
                <Gallery items={images} showThumbnails={true} thumbnailPosition="left" autoPlay={false} showNav={true} />
            </Grid>
        </Grid>
    );
}

export default Experiences;