import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Stack, Alert, AlertTitle } from '@mui/material';
import * as Constants from '../utils/Constants';


const libraries = ['places'];

const containerStyle = {
  height: "300px"
};

const center = {
  lat: Constants.Latitude,
  lng: Constants.Longitude
};

const Map = () => {

  const { isLoaded, loadError } = useJsApiLoader({
    id: Constants.GoogleMapsId,
    googleMapsApiKey: Constants.GoogleMaps_ApiKey,
    libraries
  })

  if (loadError) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">
          <AlertTitle sx={{ fontWeight: "bold" }}>Error</AlertTitle>
          No se puede cargar el mapa
        </Alert>
      </Stack>
    )
  }

  if (!isLoaded) {
    return (
      <div>Cargando...</div>
    )
  }


  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
    >
      <Marker position={center} />
    </GoogleMap>
  )
}

export default Map;