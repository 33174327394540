import React from 'react';
import { Typography, Grid, Divider, Box, Container } from '@mui/material'
import { Gallery, ServicesList } from '../components'
import { Services } from '../data'


const images = [
  {
    original: require("../images/home/home1.jpg")
  },
  {
    original: require("../images/home/home2.jpg")
  },
  {
    original: require("../images/home/home3.jpg")
  },
  {
    original: require("../images/home/home4.jpg")
  }
];


const Home = () => {

  return (
    <>
      <Grid sx={{ background: "#3b7b9f" }} container direction="column" alignItems="center">
        <Grid item>
          <Gallery items={images} showThumbnails={false} autoPlay={true} showNav={false} slideDuration="60" />
        </Grid>
      </Grid>
      <Container>
        <Grid sx={{ paddingTop: 5, textAlign: "center", fontSize: { xs: "0.8rem", sm: "0.85rem" } }} container direction="column" alignItems="center" spacing={4} display="flex">
          <Grid item>
            <Divider variant="fullWidth">
              <Typography variant="h5">LO QUE OFRECEMOS</Typography>
            </Divider>
            <Box>
              <p>Soporte técnico para asistir a mejorar sus procesos y/o productos.</p>
              <p>Servicios econónicamente razonables, con calidad, seguridad, medio ambiente y responsabilidad social.</p>
              <p>Conocer los procedimientos y reglamentos internos previos a la prestación de servicios.</p>
            </Box>
          </Grid>
          <Grid item sx={{ paddingTop: 2 }}>
            <ServicesList items={Services} source="services" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Home;