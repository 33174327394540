import React from 'react';
import { IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom';


const BottomNav = (props) => {

  const navigate = useNavigate()

  const _navegate = (event, path) => {
    if (!path.startsWith('https://')) {
      navigate(path)
    } else {
      window.open(path, '_blank')
    }
  }

  return (
    <>
      {props.options.map((option, index) => (
        <IconButton sx={{ color: "inherit", paddingLeft:2, paddingRight:2 }} key={index} label={option.label} onClick={(e) => { _navegate(e, option.path) }} >
          {option.icon}
        </IconButton>
      ))}
    </>
  );
}

export default BottomNav;