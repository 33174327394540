import { createContext } from "react";


const UserContext = createContext({
    name: null,
    years: null,
    isAuthenticated: false
})

export default UserContext;
