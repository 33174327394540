import React from 'react';
import { Grid, Container, CardMedia, IconButton } from '@mui/material';
import { Call } from '@mui/icons-material'
import { Logo } from '../../images';


const Banner = () => {
  return (
    <div className='banner'>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={9}>
            <CardMedia sx={{ width:400 }} component="img" image={Logo} alt="Hydro Electric" />
          </Grid>
          <Grid item xs={0} sm={3} >
            <Grid sx={{ fontSize: '0.9rem', display: { xs: "none", sm: "flex" } }} container item direction="row" alignItems="center" spacing={0} className='bannerText' >
              <Grid item>Soluciones mecánicas y/o eléctricas</Grid>
              <Grid item >
                <IconButton sx={{ color: "inherit" }} href="tel:+511 993711539"><Call fontSize="medium" /></IconButton>
                +511 993711539
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div >
  );
}

export default Banner;