import React, { useEffect, useState } from 'react';
import { Stack, Alert, AlertTitle } from '@mui/material'


const Error = (props) => {

  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (props.message) {
      setMessage(props.message)
    } else {
      setMessage("Se ha producido un error. Cosulte con el administrador del portal.")
    }
  }, [props.message]);

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle sx={{ fontWeight: "bold" }}>Error</AlertTitle>
        {message}
      </Alert>
    </Stack>
  );
}

export default Error;