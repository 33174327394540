import React, { Suspense } from 'react';
import { Outlet } from "react-router-dom";
import { Typography, Container } from '@mui/material';
import { Footer, Header } from '../components';


const LayoutPrivate = () => {

    return (
        <>
            <Header />
            <Typography variant='h4' >Zona Privada</Typography>
            <main>
                <Suspense fallback={<div>Cargando...</div>}>
                    <Container sx={{ marginTop: 5, marginBottom: 5 }}>
                        <Outlet />
                    </Container>
                </Suspense>
            </main>
            <Footer />
        </>
    );
}

export default LayoutPrivate;