import React from 'react';
import { Grid, MenuList, MenuItem, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';


const Menu = (props) => {
    const currentLocation = useLocation();

    const _open = () => {
        if (props.setOpen) {
            props.setOpen(false)
        }
    }

    const checkSelected = (path) => {
        if(path === "/") {
            const selected = (path === currentLocation.pathname) ? true : false
            return selected
        }
        else {
            return currentLocation.pathname.startsWith(path)            
        }
    }

    return (
        <MenuList elevation={0}>
            {props.options.map((option, index) => (
                <MenuItem sx={{ fontSize: "0.9rem" }} key={index} to={option.path} selected={checkSelected(option.path)} component={NavLink} onClick={_open} classes={{ root: props.align, selected: 'menuItemSelected' }}>
                    <Grid container alignItems='center'>
                        {option.icon}
                        <Typography sx={{ paddingLeft: 0.5 }} variant="inherit">{option.label}</Typography>
                    </Grid>
                </MenuItem>
            ))}
        </MenuList>
    );
}

export default Menu;
