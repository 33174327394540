import React, { useState } from 'react';
import { Grid, Drawer, AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Home, Contacts, WorkHistory, ListAlt, Engineering, LinkedCamera, Menu as MenuIcon } from '@mui/icons-material'
import Menu from './Menu';
import Banner from './Banner'


const options = [
  {
    "label": "INICIO",
    "path": "/",
    "icon": <Home fontSize="medium" />
  },
  {
    "label": "NOSOTROS",
    "path": "/about",
    "icon": <WorkHistory fontSize="medium" />
  },
  {
    "label": "SERVICIOS",
    "path": "/services",
    "icon": <ListAlt fontSize="medium" />
  },
  {
    "label": "PROYECTOS",
    "path": "/projects",
    "icon": <LinkedCamera fontSize="medium" />
  },
  {
    "label": "EXPERIENCIAS",
    "path": "/experiences",
    "icon": <Engineering fontSize="medium" />
  },
  {
    "label": "CONTÁCTENOS",
    "path": "/contact",
    "icon": <Contacts fontSize="medium" />
  }
];

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Banner />
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ display: { xs: "flex", sm: "none" } }} alignItems='center'>
            <IconButton sx={{ color: "inherit" }} size="large" onClick={() => setOpenDrawer(true)} edge="start">
              <MenuIcon />
            </IconButton>
            <Typography sx={{ flexGrow: 1 }}>
              Soluciones mecánicas y/o eléctricas
            </Typography>
          </Box>
          <Grid sx={{ display: { xs: "none", sm: "flex" } }} container direction="column" alignItems="center">
            <Menu options={options} align="menuHorizontal" />
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer sx={{ display: { xs: "flex", sm: "none" } }} open={openDrawer} anchor="left" onClose={() => setOpenDrawer(false)}>
        <Box sx={{ width: 220 }}>
          <Menu options={options} setOpen={setOpenDrawer} />
        </Box>
      </Drawer>
    </>
  );
}

export default Header;