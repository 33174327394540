import React, {useState} from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import Tasks from './Tasks';


const Customers = (props) => {

    const [expanded, setExpanded] = useState(true);

    const _changeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };


    return (
        <>
            {
                props.items ?
                    props.items.map((item) => (
                        <Accordion key={item.id} expanded={expanded} onChange={_changeAccordion(item.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={item.id + "bh-content"}
                                id={item.id + "bh-header"}
                                sx={{ textAlign: "left", background: "#00000005" }}>
                                <Typography sx={{ textTransform: "uppercase"}}>
                                    {item.customer}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tasks items={item.tasks} customer={item.customer} />
                            </AccordionDetails>                            
                        </Accordion>
                    ))
                    : undefined
            }
        </>
    );
}

export default Customers;
