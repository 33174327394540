import React from 'react';
import { Container, Link, Grid, Typography } from '@mui/material';
import { Home, Contacts, Facebook, WhatsApp } from '@mui/icons-material'
import BottomNav from './BottomNav';


const options = [
    {
        "label": "Inicio",
        "path": "/",
        "icon": <Home fontSize="large" />
    },
    {
        "label": "Contáctenos",
        "path": "/contact",
        "icon": <Contacts fontSize="large" />
    },
    {
        "label": "Facebook",
        "path": "https://www.facebook.com/hydroelectricingenieros",
        "icon": <Facebook fontSize="large" />
    },
    {
        "label": "WhatsApp",
        "path": "https://wa.me/51993711539",
        "icon": <WhatsApp fontSize="large" />
    }
];

const Footer = () => {

    return (
        <div className='footer'>
            <Container>
                <Grid container direction="column" alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant='h5'>SÍGUENOS</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ fontSize: '0.8rem', color: '#B3B3B3', textAlign: 'center' }}>Podrás encontrar más información de la empresa y de nuestro personal, así como fotos y videos de nuestros trabajos.</Typography>
                    </Grid>
                    <Grid item >
                        <BottomNav options={options} />
                    </Grid>
                    <Grid item >
                        <Typography sx={{ fontSize: '0.8rem', textAlign: 'center' }}>
                            © 2024 Todos los derechos reservados | Diseñado por <Link sx={{ fontWeight: "bold" }} href="./" > HYDRO ELECTRIC INGENIEROS S.R.L.</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;